import React, { useState, useEffect } from 'react';
import MainLayout from './../layouts/MainLayout';
import { getApplications } from '../utils/clients/clientManagerService';
import CustomAppGrid from '../components/CustomAppGrid';
import { useKeycloak } from '@react-keycloak/web';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function ApplicationsView(){

    const [apps, setApps] = useState([]);

    const {keycloak} = useKeycloak();
    const accessToken = keycloak.token;

    const navigate = useNavigate();

    useEffect(() => {
      getApplications(accessToken)
        .then((response) => {
            setApps(response);
        })
    }, [])
    
    const OpenNewAppForm = () => {
        navigate('/applications/new');
    }

    return(
        <MainLayout menu="3">
            <Row 
            //justify={'end'}
            >
                <Col span={2}>
                    <Button type="primary" icon={<PlusOutlined />} size={20} onClick={OpenNewAppForm}>
                        Create Application
                    </Button>
                </Col>
            </Row>
           <CustomAppGrid items={{apps}}/>
        </MainLayout>
    )
}

export default ApplicationsView;