import React from 'react';
import { Table, Space } from 'antd';


const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <a>Remove</a>
          <a>Delete</a>
        </Space>
      ),
    }
];

function AppResourcesForm(){
    return (
        <Table columns={columns} />
    );

}

export default AppResourcesForm;