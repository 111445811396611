import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { getClientSecret } from '../../../utils/clients/clientManagerService';
import { Button,Card, Row, Space, notification} from 'antd';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

function AppInstallationForm(props){

    const app = props.app;
    const {keycloak} = useKeycloak();

    const [config, setConfig] = useState();
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
      
        if(app.publicClient){

            let c = {
                'auth-server-url': window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_URL + '/realms/' + window.__RUNTIME_CONFIG__.REACT_APP_REALM_NAME,
                'credentials': {
                    'client-id': app.clientId
                },
                realm: window.__RUNTIME_CONFIG__.REACT_APP_REALM_NAME,
                'umm-api-url': window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI + '/api'
            };

            setConfig(c);
        }
        else{
            getClientSecret(keycloak.token, app.id)
            .then((response) => {
            
            let c = {
                'auth-server-url': window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_URL + '/realms/' + window.__RUNTIME_CONFIG__.REACT_APP_REALM_NAME,
                'credentials': {
                    'client-id': app.clientId,
                    'client-secret': response.value
                },
                realm: window.__RUNTIME_CONFIG__.REACT_APP_REALM_NAME,
                'umm-api-url': window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI
            };

            setConfig(c);

        });
        }
        
    }, [])

    const DownloadAsJson = () =>{
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([""+JSON.stringify(config, null, 2)], { type: "application/json" }));
        a.download = app.clientId + "-config.json";
        a.click();

        openNotification("Download will start soon...");
    }

    const openNotification = (msg) => {
        notification.success({
          message: 'Success',
          placement: 'bottomRight',
          duration: 3, //time in seconds
          description: msg
        });
      };

    return (
        <>
        <Card>
            <h3 align="center"><a rel="noreferrer" target="_blank" href={window.__RUNTIME_CONFIG__.REACT_APP_OPENID_CONNECT_URL}>View OpenID Connect configuration</a></h3>
            <pre>
                {JSON.stringify(config, null, 2)}
            </pre>
        </Card>
        <br/>
        <Row justify={'center'}>
            
            <Space>
                <CopyToClipboard text={JSON.stringify(config, null, 2)} onCopy={() => {
                    setIsCopied(true);
                    openNotification("Configuration copied successfully!")
                    }}>
                    <Button><CopyOutlined /> Copy</Button>
                </CopyToClipboard>
                <Button type='primary' onClick={DownloadAsJson}> <DownloadOutlined/> Download</Button>
            </Space>
        </Row>
        </>
    );

}

export default AppInstallationForm;