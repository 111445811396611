import React from 'react';
import {Card, Row, Col, Avatar} from 'antd';
import {ApartmentOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function CustomAppGrid(props){

    const cards = [];
    const items = props.items.apps;

    const { Meta } = Card;

    const navigate = useNavigate();

    const OpenDetails = (e, id) => {
        navigate('/applications/'+id);
    }

    for (let index = 0; index < items.length; index++) {
        cards.push(
            <Col span={6} key={index}>
                <Card
                    hoverable={true}
                    /* style={{
                    width: 300,
                    marginTop: 16,
                    }} */
                    onClick={(e) =>{OpenDetails(e, items[index].id)}}
                >
                    <Meta
                    avatar={<Avatar icon={<ApartmentOutlined />} style={{
                        backgroundColor: '#d4eff9',
                        color: '#4490be',
                      }}/>}
                    title={items[index].clientId}
                    description={<><b>ROOT URL: {items[index].baseUrl ? items[index].baseUrl : "NONE"}</b><br/><b>TYPE: {items[index].publicClient ? "Public" : "Confidential"}</b> </>}
                    />
                </Card>
            </Col>
        )
    }

    return(
        <Row gutter={[16, 8]} style={{marginTop: 10, marginBottom:10}}>
            {cards}
        </Row>
    );
}