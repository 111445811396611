import React from 'react';
import { UserOutlined, SettingOutlined,HomeOutlined, TeamOutlined, ApartmentOutlined, FileProtectOutlined, LogoutOutlined} from '@ant-design/icons';
import { Menu} from 'antd';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import logo from './../logo.svg';
import { useKeycloak } from '@react-keycloak/web';

function getItem(label, key, icon, path, children, type) {
  return {
    key,
    icon,
    path,
    children,
    label,
    type
  };
}

const items = [
  {type: 'divider' },
  getItem('Home', '0', <HomeOutlined />, '/home'),
  {
    type: 'divider',
  },
  getItem('Users', '1', <UserOutlined />, '/users'),
  getItem('Groups', '2', <TeamOutlined />, '/groups'),
  getItem('Applications', '3', <ApartmentOutlined />, '/applications'),
  {
    type: 'divider',
  },
  getItem('Documentation', '4', <FileProtectOutlined />, window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI),
  getItem('Account', 'account', <SettingOutlined />),
  {
    type: 'divider',
  },
  getItem('Sign Out', 'logout', <LogoutOutlined />)
].map((item, index) => {
    if(item.key === '4'){
      return {
        key: item.key,
        label: (<a href={item.path} target="_self" rel="noopener noreferrer">{item.label}</a>),
        icon: item.icon,
      };
    }
    if(item.key === 'account'|| item.key === 'logout'){
      return item;
    }
    if(typeof item.type === 'undefined'){
        return {
                key: item.key,
                label: <Link to={item.path}>{item.label}</Link>,
                icon: item.icon
            };
    }
    else{
        return item;
    }
  });

function SideBar(props){

    const id = props.menu; //passing menu item key

    const {keycloak} = useKeycloak();

    const OnMenuItemClick = (e) =>{
    
      if(e.key === 'account'){
        window.open(keycloak.createAccountUrl(), "_self")
      }

      if(e.key === 'logout'){
        /* alert(keycloak.createLogoutUrl());
        alert(keycloak.createLogoutUrl({
          redirectUri: process.env.REACT_APP_REDIRECT_URI
        })); */
        window.open(keycloak.createLogoutUrl(), "_self")
      }

    }

    return (
        <div>
            <div
            style={{
                height: 160,
                padding: 20,
                borderBottomRightRadius: 10
            }}
            >
              <Row justify="space-around" align="middle">
                <Col span={20}>
                  <img src={logo} alt="logo"/>
                </Col>
              </Row>
            </div>
            <Menu
            defaultSelectedKeys={[id]}
            mode="inline"
            theme='light'
            items={items}
            onClick={(e) =>{OnMenuItemClick(e)}}
            />
        </div>
      );
}

export default SideBar;
