import React, {useState} from 'react';
import { ConfigProvider, FloatButton, Tooltip, theme} from 'antd';
import {BrowserRouter} from 'react-router-dom';
import keycloak from './keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { SettingFilled, BgColorsOutlined} from '@ant-design/icons';

import MyRoutes from './routes/MyRoutes';

function App() {

const { defaultAlgorithm, darkAlgorithm } = theme;
 const [isDarkMode, setIsDarkMode] = useState(false);

 const onChangeThene = () => {
  setIsDarkMode((previousValue) => !previousValue);
 };

 const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  checkLoginIframe: false
}

//keycloak.init(keycloakProviderInitConfig);

  return (
   /*  <Provider store={store}>
      <ConfigProvider
          theme={{
              algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
              token: {
                  "colorPrimary": "#"+ process.env.REACT_APP_PRIMARY_COLOR,
                  "fontSize": 15,
                  "borderRadius": 14
              }
          }}
      >
        
         <BrowserRouter>
            <Routes>
              <Route path="/home" element={<HomeView/>} />
              <Route path="/users" element={<UsersView/>} />
              <Route path="/groups" element={<GroupsView/>} />
              <Route path="/applications" element={<ApplicationsView/>} />

              <Route path="/unauthorized" element={<UnAuthorizedView/>} />
            </Routes>
            <Authorisation token={token} setToken={setToken}/>
            <FloatButton.Group
              trigger="click"
              type="primary"
              style={{
                  right: 24,
              }}
              icon={<SettingFilled />}
              >
              <Tooltip title="Dark Theme">
                  <FloatButton icon={<BgColorsOutlined />} onClick={onChangeThene}/>
              </Tooltip>
              
          </FloatButton.Group>
          </BrowserRouter>

      </ConfigProvider>
    </Provider> */
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
      <ConfigProvider
          theme={{
              algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
              token: {
                  "colorPrimary": "#"+ window.__RUNTIME_CONFIG__.REACT_APP_PRIMARY_COLOR,
                  "fontSize": 15,
                  "borderRadius": 14
              }
          }}
      >
        <BrowserRouter>
          <MyRoutes/>
        </BrowserRouter>
        <FloatButton.Group
            trigger="click"
            type="primary"
            style={{
                right: 24,
            }}
            icon={<SettingFilled />}
            >
            <Tooltip title="Dark Theme">
                <FloatButton icon={<BgColorsOutlined />} onClick={onChangeThene}/>
            </Tooltip>
              
        </FloatButton.Group>
      </ConfigProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
