import React, {useEffect, useState} from 'react';
import { Row, Col, Spin } from 'antd';

import MainLayout from '../../layouts/MainLayout';
import GroupConfigTab from './GroupConfigTab';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getGroupDetailsPerId } from '../../utils/groups/groupManagerService';

function GroupInfoView(){

    const {id} = useParams();
    const {keycloak} = useKeycloak();

    const token = keycloak.token;

    const [group, setGroup] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      getGroupDetailsPerId(token, id)
      .then((response) => {
        setGroup(response);
        setIsLoading(false);
        });
    }, [])

    return(
        <MainLayout menu="2">
            <Row justify={'center'}>
                <Col span={16}>
                    <Spin spinning={isLoading} tip="Loading...">
                        <GroupConfigTab group={group}/>
                    </Spin>
                    
                </Col>
            </Row>
        </MainLayout>
    )
}

export default GroupInfoView;