import React, {useEffect, useState} from 'react';
import { Row, Col, Card, Statistic, Skeleton } from 'antd';
import {UserOutlined, TeamOutlined, ApartmentOutlined, FileProtectOutlined} from '@ant-design/icons';
import MainLayout from './../layouts/MainLayout';
import { Link } from 'react-router-dom';
import { getApplications } from '../utils/clients/clientManagerService';
import { getUsersCount } from '../utils/users/userManagerService';
import { getGroupsCount } from '../utils/groups/groupManagerService';
import { useKeycloak } from '@react-keycloak/web';

function HomeView(){

    //const accessToken = useSelector(selectAccessToken);

    const [isLoading, setIsLoading] = useState(true);

    const {keycloak} = useKeycloak();
    const accessToken = keycloak.token;

    const [clients, setClients] = useState(0);
    const [users, setUsers] = useState(0);
    const [groups, setGroups] = useState(0);
    
    useEffect(() => {
        getApplications(accessToken)
            .then((response) => {
                setClients(response.length);
            });
            //TODO handle in a nicer way exception

        getUsersCount(accessToken)
            .then((response) => {
                setUsers(response)
            });
        
        getGroupsCount(accessToken)
            .then((response) => {
                setGroups(response.count)
            });
        
        setIsLoading(false)
    }, [])
    
    

    return(
        <MainLayout menu="0">
            <div>
            <Row gutter={[16, 16]} style={{height: 350, marginBottom: 16}} justify="center" align={'bottom'}>
                
                <Col span={4}> 
                    <Card bordered={true} hoverable={true}>
                        <Skeleton loading={isLoading}>
                            <Link to="/users">
                            <UserOutlined style={{color:'#000000'}}/>
                            <Statistic
                            title="Users"
                            value={users}
                            valueStyle={{
                                color: '#344271',
                                fontSize: 80,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                            />
                            </Link>
                        </Skeleton>
                    </Card>
                </Col>
                <Col span={4}> 
                    <Card bordered={true} hoverable={true}>
                        <Skeleton loading={isLoading}>
                            <Link to="/groups">
                                <TeamOutlined style={{color:'#000000'}}/>
                                <Statistic
                                title="Groups"
                                value={groups}
                                valueStyle={{
                                    color: '#344271',
                                    fontSize: 80,
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                                />
                            </Link>
                        </Skeleton>
                    </Card>
                </Col>
                
            </Row>
            <Row gutter={[16, 16]}justify="center" align={'middle'}>
            <Col span={4}> 
                    <Card bordered={true} hoverable={true}>
                        <Skeleton loading={isLoading}>
                            <Link to="/applications">
                                <ApartmentOutlined style={{color:'#000000'}}/>
                                <Statistic
                                title="Applications"
                                value={clients}
                                valueStyle={{
                                    color: '#344271',
                                    fontSize: 80,
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}
                                />
                            </Link>
                        </Skeleton>
                    </Card>
                </Col>

                <Col span={4} > 
                    <Card bordered={true} hoverable={true}>
                        <Skeleton loading={isLoading}>
                            <Link to="/doc">
                                <FileProtectOutlined style={{color:'#000000'}}/>
                                <Statistic
                                title="Documentation"
                                value={window.__RUNTIME_CONFIG__.REACT_APP_REALM_NAME
                                }
                                style={{
                                    height: 145
                                }}
                                valueStyle={{
                                    color: '#344271',
                                    fontSize: 70,
                                    fontWeight: 'bold'
                                }}
                                />
                            </Link>
                        </Skeleton>
                    </Card>
                </Col>
            </Row>
            </div>
        </MainLayout>
    )
}

export default HomeView;