import { useKeycloak } from '@react-keycloak/web';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import MainLayout from '../../layouts/MainLayout';
import { Col, Row, Spin } from 'antd';
import AppConfigTab from './AppConfigTab';
import { getClientDetailsPerId } from '../../utils/clients/clientManagerService';

function AppInfoView(){

    const {id} = useParams();
    const {keycloak} = useKeycloak();

    const token = keycloak.token;

    const [isLoading, setIsLoading] = useState(true);

    const [app, setApp] = useState({
        webOrigins: ['*'],
        redirectUris: []
    });

    useEffect(() => {
      getClientDetailsPerId(token, id)
      .then((response) => {
        setApp(response);
        setIsLoading(false)
        });
    }, [])
    
    
    return (
        <MainLayout menu="3">
            <Row justify={'center'}>
                <Col span={16}>
                    <Spin spinning={isLoading} tip="Loading...">
                        <AppConfigTab app={app}/>
                    </Spin>
                </Col>
            </Row>
        </MainLayout>
    );
}

export default AppInfoView;