import React, {useEffect, useState} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Form, Button, Input, Collapse, Switch, Row, Popconfirm, Spin} from 'antd';

import { showErrorMessage, showSuccessMessage } from '../../../utils/utils';
import { deleteUser, updateExistingUser } from '../../../utils/users/userManagerService';
import { useNavigate } from 'react-router-dom';
//import CustomDeleteButton from '../../CustomDeleteButton';

const { Panel } = Collapse;

function ProfileConfigForm(props){

    const user = props.user;

    const [form] = Form.useForm();

    const {keycloak} = useKeycloak();

    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        form.setFieldsValue({
            email: user.email,
            firstname: user.firstname ? user.firstname: null,
            lastname: user.lastname ? user.lastname: null,
            username: user.username,
            isenabled: user.enabled
        });
    }, [user, form])
    
    const DeleteUser = () =>{

        //setIsUpdating(true);

        deleteUser(keycloak.token, user.id)
        .then((resp) => {
            //setIsUpdating(false);
            setIsDeleting(false);
            showSuccessMessage("User deleted successfully!");
            navigate('/users')
        }).catch((err) => {
            //setIsUpdating(false);
            setIsDeleting(false);
            showErrorMessage("Something went wrong! Error: "+ err.message)
            console.log(err)
        })
    }

    const onFinish = () =>{
        form
          .validateFields()
          .then((values) => {
            //form.resetFields();
            //alert(JSON.stringify(values));

            setIsUpdating(true);

            let bodyContent = {
                email: values.email,
                firstName: (values.firstname == null) ? '' : values.firstname,
                lastName: (values.lastname == null) ? '' : values.lastname,
                username: values.username,
                password: '',
                enabled: values.enabled
            }

            let data = JSON.stringify(bodyContent);

            setTimeout(() => {
                updateExistingUser(keycloak.token, user.id, data)
                .then((resp) => {
                    //Disable loading state
                    setIsUpdating(false);
                    showSuccessMessage("User updated successfully!");
                }).catch((err) => {
                    setIsUpdating(false);
                    showErrorMessage("Something went wrong! Error: "+ err.message)
                    console.log(err)
                })
            }, 3000);

          }
        )
    }

    return (
        <Spin spinning={isUpdating} tip="Loading...">
            <h2>Basic Information</h2>
            <Form
                form={form}
                name="profile"
                labelCol={{span: 4}}
                wrapperCol={{span: 24}}
                style={{padding: 25}}
                onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                    required: true,
                    message: 'Please input a valid email!',
                    },
                ]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                    required: false
                    },
                ]}
                >
                <Input disabled/>
                </Form.Item>

                <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                    {
                    required: false,
                    message: 'Please input your first name!',
                    },
                ]}
                >
                <Input placeholder="Enter your first name here..."/>
                </Form.Item>
                <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                    {
                    required: false,
                    message: 'Please input your last name!',
                    },
                ]}
                >
                <Input placeholder="Enter your last name here..."/>
                </Form.Item>

                <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                    <Panel header="Advanced Settings" key="1">
                        <Form.Item labelCol={8} label="Is Enabled?" name="enabled" valuePropName="checked" initialValue={true}>
                            <Switch />
                        </Form.Item>
                    </Panel>
                </Collapse>

                <Form.Item>
                    <Row style={{marginTop: 30}} justify={'space-around'}>
                            <Popconfirm
                                title="Delete the user"
                                description="Are you sure to delete this user?"
                                onConfirm={DeleteUser}
                                placement='bottom'
                                okText="Yes"
                                okType='danger'
                                cancelText="No"
                            >
                                <Button danger={true}>Delete User</Button>
                            </Popconfirm>
                            {/* <CustomDeleteButton nature="User" parentMethod={DeleteUser}/> */}
                            <Button type="primary" htmlType="submit">Save Changes</Button>
                    </Row>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default ProfileConfigForm;