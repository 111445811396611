import React, { useState } from 'react';
import { Row, Col, Form, Input, Divider, Collapse, Card, Button, Tabs, Switch, Spin} from 'antd';
import MainLayout from '../../layouts/MainLayout';
import GroupAssignComponent from './forms/GroupAssignComponent';
import { useForm } from 'antd/es/form/Form';

import { v4 as uuid4 } from 'uuid';
import { createNewUser } from '../../utils/users/userManagerService';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

const { Panel } = Collapse;

const items = [
    {
        key: '0',
        label: `Availability`,
        children: (
            <>
                <Form.Item labelCol={8} label="Is Enabled?" name="enabled" valuePropName="checked" initialValue={true}>
                    <Switch />
                </Form.Item>
            </>
        ),
    },
    {
      key: '1',
      label: `Groups`,
      children: (<GroupAssignComponent/>),
    },
    {
      key: '2',
      label: `Authentication`,
      children: (<p>Client doesn't need a password?</p>),
      disabled: true
    }
];

function UserInfoCreate(){

    const [processing, setProcessing] = useState(false);

    const [form] = useForm();

    const {keycloak} = useKeycloak();

    const navigate = useNavigate();

    const extractUsername = (str) =>{
        let nameMatch = str.match(/^([^@]*)@/);
        let name = nameMatch ? nameMatch[1] : uuid4();

        return name;
    }

    const onFinish = () =>{
        form
          .validateFields()
          .then((values) => {
            //form.resetFields();
            //alert(JSON.stringify(values));

            setProcessing(true);

            let bodyContent = {
                email: values.email,
                firstName: (values.firstname == null) ? '' : values.firstname,
                lastName: (values.lastname == null) ? '' : values.lastname,
                username: extractUsername(values.email),
                password: values.password,
                enabled: values.enabled
            }

            let data = JSON.stringify(bodyContent);

            setTimeout(() => {
                createNewUser(keycloak.token, data)
                .then((resp) => {
                    //Disable loading state
                    setProcessing(false);
                    showSuccessMessage("New user created successfully!")
                    navigate('/users')
                }).catch((err) => {
                    setProcessing(false);
                    showErrorMessage("Something went wrong! Error: "+ JSON.stringify(err))
                    console.log(err)
                })
            }, 3000);

          }
        )
    }

    return (
        <>
           <MainLayout menu="1">
                <Row justify={'center'}>
                    <Col span={14}>
                        <Spin spinning={processing}>
                            <Card>
                                <h3>
                                    Profile Information
                                </h3>

                                <Form
                                    form={form}
                                    name="profile"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 24}}
                                    style={{padding: 25}}
                                    onFinish={onFinish}
                                    //onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input a valid email!',
                                        type: 'email'
                                        }
                                    ]}
                                    >
                                    <Input placeholder="Enter a valid email address e.g: something@example.com" />
                                    </Form.Item>

                                    <Form.Item
                                    label="First Name"
                                    name="firstname"
                                    rules={[
                                        {
                                        required: false,
                                        message: 'Please input your first name!',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="Enter your first name here..."/>
                                    </Form.Item>
                                    <Form.Item
                                    label="Last Name"
                                    name="lastname"
                                    rules={[
                                        {
                                        required: false,
                                        message: 'Please input your last name!',
                                        }
                                    ]}
                                    >
                                    <Input placeholder="Enter your last name here..."/>
                                    </Form.Item>

                                    <Divider><h3>Security</h3></Divider>

                                    <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                        required: true,
                                        min:8,
                                        message: 'Password must have at least 8 characters!',
                                        },
                                    ]}
                                    >
                                    <Input.Password placeholder="Enter a password minumum of 8 characters"/>
                                    </Form.Item>

                                    <br/>

                                    <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                                        <Panel header="Advanced Settings" key="1">
                                            {/* <Form.Item label="Is Enabled" name="isenabled" valuePropName="checked">
                                                <Switch />
                                            </Form.Item> */}
                                            <Tabs
                                                defaultActiveKey="0"
                                                items={items}
                                                centered
                                            />
                                        </Panel>
                                    </Collapse>

                                    <Form.Item>
                                        <Row style={{marginTop: 30}} justify={'space-around'}>
                                                <Button type="primary" htmlType="submit">Save Changes</Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </MainLayout>
        </>
    );
}

export default UserInfoCreate;