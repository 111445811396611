import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Input, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import MainLayout from './../../layouts/MainLayout';
import { useKeycloak } from '@react-keycloak/web';
import { createNewGroup } from '../../utils/groups/groupManagerService';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

function GroupInfoCreate(){

    const [form] = useForm();

    const [processing, setProcessing] = useState(false);

    const {keycloak} = useKeycloak();

    const navigate = useNavigate();

    const onFinish = () => {
        form
          .validateFields()
          .then((values) => {

            setProcessing(true);

            let bodyContent = {
                name: values.name,
                path: '/' + values.name
            }

            let data = JSON.stringify(bodyContent);

            setTimeout(() => {
                createNewGroup(keycloak.token, data)
                .then((resp) => {
                    //Disable loading state
                    setProcessing(false);
                    showSuccessMessage("New group created successfully!")
                    navigate('/groups')
                }).catch((err) => {
                    setProcessing(false);
                    showErrorMessage("Something went wrong! Error: "+ err.message)
                    console.log(err)
                })
            }, 3000);

          }
        )
    }

    return (
           <MainLayout menu="2">
                <Row justify={'center'}>
                    <Col span={14}>
                        <Spin spinning={processing} tip="Saving...">
                            <Card>
                                <h3>
                                    Basic Information
                                </h3>

                                <Form
                                    form={form}
                                    name="group"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 24}}
                                    style={{padding: 25}}
                                    onFinish={onFinish}
                                    //onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                    label="Group Name"
                                    name="name"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input a valid and unique group name!',
                                        },
                                    ]}
                                    >
                                    <Input />
                                    </Form.Item>

                                    <Form.Item>
                                        <Row style={{marginTop: 30}} justify={'space-around'}>
                                                <Button type="primary" htmlType="submit">Save Changes</Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </MainLayout>
    )
}

export default GroupInfoCreate;