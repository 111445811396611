import React, {useState, useEffect} from 'react';
import MainLayout from './../layouts/MainLayout';
import { getGroupMembersPerGroupId, getGroups } from '../utils/groups/groupManagerService';
import CustomGroupGrid from '../components/CustomGroupGrid';
import { useKeycloak } from '@react-keycloak/web';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';


function GroupsView(){

    const [isLoading, setIsLoading] = useState(true);

    const [groups, setGroups] = useState([]);
    const {keycloak} = useKeycloak();
    const accessToken = keycloak.token;

    const navigate = useNavigate();

    useEffect(() => {
      getGroups(accessToken)
        .then((response) => {
             let grps = [];

            /* for (let i = 0; i < response.length; i++) {
                getGroupMembersPerGroupId(accessToken, response[i].id)
                    .then((r) => {
                        grps.push({
                            group: response[i],
                            members: r.length
                        });
                    })
                
            } */

            //setIsLoading(false);

            setGroups(response);
        })
    }, [])

    const OpenNewGroupForm = () =>{
        navigate('/groups/new');
    }

    return(
        <MainLayout menu="2">
            <Row 
            //justify={'end'}
            >
                <Col span={2}>
                    <Button type="primary" icon={<PlusOutlined />} size={20} onClick={OpenNewGroupForm}>
                        Create Group
                    </Button>
                </Col>
            </Row>
            {/* <Spin spinning={isLoading} tip="Loading...">
                <CustomGroupGrid items={{groups}}/>
            </Spin> */}
            <CustomGroupGrid items={{groups}}/>
        </MainLayout>
        
    )
}

export default GroupsView;