import apiRequest from "../../api/apiInstance";

export const getUsersCount = async (token) => {
    const config = {
        method: 'GET',
        url: '/Users/count',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getUsers = async (token) =>{
    
    const config = {
        method: 'GET',
        url: '/Users',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getUserInfo = async (token) =>{
    const config = {
        method: 'GET',
        url: '/Auth/UserInfo',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getUserDetailsPerId = async (token, userId) =>{
    const config = {
        method: 'GET',
        url: '/Users/'+ userId,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const createNewUser = async(token, data) =>{
    const config = {
        method: 'POST',
        url: '/Users',
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const updateExistingUser = async(token, userId, data) =>{
    const config = {
        method: 'PUT',
        url: '/Users/'+ userId,
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const deleteUser = async(token, userId) =>{
    const config = {
        method: 'DELETE',
        url: '/Users/'+ userId,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}