import { Card, Tabs } from 'antd';
import React from 'react';
import ProfileConfigForm from './forms/ProfileConfigForm';
import AuthConfigForm from './forms/AuthConfigForm';
import RoleConfigForm from './forms/RoleConfigForm';

function UserConfigTab(props){

  const user = props.user;

  const items = [
    {
      key: '1',
      label: `Profile`,
      children: (<Card><ProfileConfigForm user={user}/></Card>), //Passing straight to children the user object
    },
    {
      key: '2',
      label: `Authentication`,
      children: (<Card><AuthConfigForm user={user}/></Card>),
    },
    {
      key: '3',
      label: `Roles`,
      children: (<Card><RoleConfigForm user={user}/></Card>),
    },
  ];

    return (
        <Tabs defaultActiveKey="1" centered items={items} size={20}/>
    );
}

export default UserConfigTab;