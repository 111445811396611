import { Card, Tabs } from 'antd';
import React from 'react';
import AppCredentialsForm from './forms/AppCredentialsForm';
import AppSettingsForm from './forms/AppSettingsForm';
import AppResourcesForm from './forms/AppResourcesForm';
import AppInstallationForm from './forms/AppInstallationForm';

function AppConfigTab(props){

  const app = props.app;

  const items = [
    {
      key: '1',
      label: `Settings`,
      children: (<Card><AppSettingsForm app={app}/></Card>),
    },
    {
      key: '2',
      label: `Credentials`,
      children: (<Card><AppCredentialsForm app={app}/></Card>),
      disabled: app.publicClient //Don't show the tab if the client is public
    },
    {
      key: '3',
      label: `Resources`,
      children: (<Card><AppResourcesForm app={app}/></Card>),
      disabled: app.publicClient
    },
    {
      key: '4',
      label: `Installation`,
      children: (<Card><AppInstallationForm app={app}/></Card>),
    },
  ];

    return (
        <Tabs defaultActiveKey="1" centered items={items} size={20}/>
    );
}

export default AppConfigTab;