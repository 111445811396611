import React from 'react';

function SubGroupsConfigForm(){
    return(
        <>
            SubGroup List
        </>
    )
}

export default SubGroupsConfigForm;