import { Form, Input, Button, Row, Spin, Popconfirm} from 'antd';
import React, {useEffect, useState} from 'react';
import { deleteGroup, editExistingGroup } from '../../../utils/groups/groupManagerService';
import { showErrorMessage, showSuccessMessage } from '../../../utils/utils';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

function SettingsConfigForm(props){

    const group = props.group;

    const [form] = Form.useForm();

    const {keycloak} = useKeycloak();

    const [processing, setProcessing] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        form.setFieldsValue({
            name: group.name
        });
    }, [group, form])

    const onFinish = () => {
        form
          .validateFields()
          .then((values) => {

            setProcessing(true);

            let bodyContent = {
                name: values.name,
                path: '/' + values.name
            }

            let data = JSON.stringify(bodyContent);

            setTimeout(() => {
                editExistingGroup(keycloak.token, group.id, data)
                .then((resp) => {
                    setProcessing(false);
                    showSuccessMessage("Group edited successfully!")
                }).catch((err) => {
                    setProcessing(false);
                    showErrorMessage("Something went wrong! Error: "+ err.message)
                    console.log(err)
                })
            }, 3000);

          }
        )
    }

    const DeleteGroup = () => {
        setProcessing(true);

        deleteGroup(keycloak.token, group.id)
        .then((resp) => {
            setProcessing(false);
            showSuccessMessage("Group deleted successfully!");
            navigate('/groups')
        }).catch((err) => {
            setProcessing(false);
            showErrorMessage("Something went wrong! Error: "+ err.message)
            console.log(err)
        })
    }

    return(
        <Spin spinning={processing} tip="Loading...">
            <h3>
                Basic Information
            </h3>
            <Form
                form={form}
                labelCol={{span: 4}}
                wrapperCol={{span: 24}}
                style={{padding: 25}}
                onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Group Name"
                    name="name"
                    rules={[
                        {
                        required: true,
                        message: 'Please input a valid and unique group name!',
                        },
                    ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item>
                        <Row style={{marginTop: 30}} justify={'space-around'}>
                                <Popconfirm
                                        title="Delete the group"
                                        description="Are you sure to delete this group?"
                                        onConfirm={DeleteGroup}
                                        placement='bottom'
                                        okText="Yes"
                                        okType='danger'
                                        cancelText="No"
                                    >
                                    <Button danger={true}>Delete User</Button>
                                </Popconfirm>
                                <Button type="primary" htmlType="submit">Save Changes</Button>
                        </Row>
                    </Form.Item>
            </Form>
        </Spin>
    )
}

export default SettingsConfigForm;