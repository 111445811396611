import React, {useState, useEffect} from 'react';
import MainLayout from './../layouts/MainLayout';
import { getUsers } from '../utils/users/userManagerService';
import CustomUserGrid from '../components/CustomUserGrid';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Col, Row, Skeleton } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function UsersView(){

    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    //const accessToken = useSelector(selectAccessToken);
    const {keycloak} = useKeycloak();
    const accessToken = keycloak.token;

    const navigate = useNavigate();

    const OpenNewUserForm = () => {
        navigate('/users/new');
    }

    useEffect(() => {
      getUsers(accessToken)
        .then((response) => {
            setUsers(response);
            setIsLoading(false);
        })
    }, [])
    return(
        <MainLayout menu="1">
            <Row 
            //justify={'end'}
            >
                <Col span={2}>
                    <Button type="primary" icon={<UserAddOutlined />} size={20} onClick={OpenNewUserForm}>
                        Create User
                    </Button>
                </Col>
            </Row>
            <Skeleton loading={isLoading}>
                <CustomUserGrid items={{users}}/>
            </Skeleton>
            
        </MainLayout>
    )
}

export default UsersView;