import React, { useEffect, useState } from 'react';
import { Table, Space, Button, Modal, Form, Input, Row} from 'antd';
import { getGroupMembersPerGroupId } from '../../../utils/groups/groupManagerService';
import { useKeycloak } from '@react-keycloak/web';

const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <a>Remove</a>
        </Space>
      ),
    }
];


function MembersConfigForm(props){

    const {keycloak} = useKeycloak();
    const group = props.group;

    const [members, setMembers] = useState();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');

    const [form] = Form.useForm();

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    useEffect(() => {
      getGroupMembersPerGroupId(keycloak.token, group.id)
      .then((response) => {
        let data = [];

        for (let i = 0; i < response.length; i++) {
            data.push({
                username: response[i].username,
                email: response[i].email
            })
        }

        setMembers(data);
    });
    }, [])
    

    return(
        <>
            <Table columns={columns} dataSource={members} />
            <Button type='primary' onClick={showModal}> Add New Member</Button>
            <Modal
                title="Add member to group"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    name="addUser"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 24}}
                    style={{padding: 25}}
                    //onFinish={onFinish}
                    //onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                  <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                      {
                      required: true,
                      message: 'Please input a valid email!',
                      },
                  ]}
                  >
                  <Input placeholder="Enter user email address"/>
                  </Form.Item>

                </Form>
            </Modal>
        </>
    )
}

export default MembersConfigForm;