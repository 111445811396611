import apiRequest from "../../api/apiInstance";

export const getApplications = async (token) =>{
    
    const config = {
        method: 'GET',
        url: '/Applications',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getClientDetailsPerId = async (token, id) =>{
    
    const config = {
        method: 'GET',
        url: '/Applications/'+ id,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getClientSecret = async(token, id) => {
    const config = {
        method: 'GET',
        url: '/Applications/'+ id + '/client-secret',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const createNewApp = async (token, data) =>{
    const config = {
        method: 'POST',
        url: '/Applications',
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const editExistingApp = async (token, appId, data) =>{

    const config = {
        method: 'PUT',
        url: '/Applications/' + appId,
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const deleteApp = async (token, appId) =>{

    const config = {
        method: 'DELETE',
        url: '/Applications/' + appId,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}