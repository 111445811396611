import React, { useState } from 'react';
import { Form, Button, Select, Row, Col} from 'antd';
import DraggableRoleComponent from './DraggableRoleComponent';
import { useEffect } from 'react';
import { getApplications } from '../../../utils/clients/clientManagerService';
import { useKeycloak } from '@react-keycloak/web';


function RoleConfigForm(){

    const [form] = Form.useForm();

    const [apps, setApps] = useState();
    const [clientSelected, setClientSelected] = useState(false);

    const {keycloak} = useKeycloak();

    const onChange = (value) => {
        //if(value != null){
            setClientSelected(true);

            //fetch app client roles and display in draggable component
        //}
      };
    
    const onSearch = (value) => {
    console.log(`selected ${value}`);
    };

    useEffect(() => {
      getApplications(keycloak.token)
      .then((response) => {
        let clients = [];
        for (let i = 0; i < response.length; i++) {
            clients.push({
                label: response[i].clientId,
                value: response[i].clientId
            })
        }
        setApps(clients);
        });
    }, [])
    

    const DraggableComponentWrapper = (clientSelected) =>{
        if(clientSelected){
            return (
                <DraggableRoleComponent/>
            )
        }
    }

    return (
        <>
        <Row>
            <Col span={24}>
            <Select
                showSearch
                placeholder="Select a client application"
                style={{width: '100%'}}
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={apps}
            />
                {DraggableComponentWrapper(clientSelected)}
            </Col>
        </Row>
        <Row style={{marginTop: 30}} justify={'space-around'}>
                <Button type="primary" htmlType="submit">Save Changes</Button>
        </Row>
        </>
    )
}

export default RoleConfigForm;