import React, {useEffect} from 'react';
import { Form, Button, Collapse, Row, Input, Switch} from 'antd';

const { Panel } = Collapse;

function AuthConfigForm(props){

    const [form] = Form.useForm();

    const user = props.user;

    useEffect(() => {
        form.setFieldsValue({
            ispasswordless: !user.enabled,
            isOTP: user.totp
            });
    }, [form, user])
    
    return (
        <>
            <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                    <Panel header="Password" key="1">
                    <Form
                        name="passwordForm"
                        labelCol={{span: 7}}
                        size='large'
                        wrapperCol={{span: 24}}
                        style={{padding: 25}}
                        //onFinish={onFinish}
                        //onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                            <Form.Item
                            label="New Password"
                            name="newpassword"
                            rules={[
                                {
                                required: true,
                                message: 'Password must have at least 8 characters',
                                },
                            ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label="Confirm New Password"
                            name="confnewpassword"
                            rules={[
                                {
                                required: true,
                                message: '2 password does not match',
                                },
                            ]}
                            >
                                <Input />
                            </Form.Item>
                            <span>Any password change made here will be a temporary password. User will be prompted to change their password upon next login.</span>
                            <Form.Item>
                                <Row style={{marginTop: 30}} justify={'space-around'}>
                                        <Button type="primary" htmlType="submit">Update Password</Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Panel>
            </Collapse>
            <br/>
            <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                    <Panel header="Passwordless" key="2">
                        <Form
                            form={form}
                        >
                            <Form.Item label="Passwordless" name="ispasswordless" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <span>
                                Enable/disable passwordless authentication. Passwordless authentication. Passwordless authentication allows users to login using a device or a security key. If enabled here, users will be required to setup passwordless authentication upon next login.
                            </span>
                            <Form.Item>
                                <Row style={{marginTop: 30}} justify={'space-around'}>
                                        <Button type="primary" htmlType="submit">Save Changes</Button>
                                </Row>
                            </Form.Item>
                        </Form>
                        
                    </Panel>
            </Collapse>
            <br/>
            <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                <Panel header="One-time Passcode (OTP)" key="3">
                <Form form={form}>
                            <Form.Item label="One-time Passcode" name="isOTP" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <span>
                                This will require the user to set p a one-time passcode using a mobile authenticator upon next login.
                            </span>
                            <Form.Item>
                                <Row style={{marginTop: 30}} justify={'space-around'}>
                                        <Button type="primary" htmlType="submit">Save Changes</Button>
                                </Row>
                            </Form.Item>
                        </Form>
                </Panel>
            </Collapse>
        </>
    )
}

export default AuthConfigForm;