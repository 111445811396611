import React from 'react';
import { Result, Button, Tooltip } from "antd";
//import { getAuthUrl } from '../config/config';

export default function UnAuthorizedView(){

    return (
        <>
            <Result
                  status="403"
                  title="403"
                  subTitle="Sorry, you are not authorized to access this page. Please Sign In here..."
                  extra={<Tooltip title="Click here to sign in"><Button type="primary">Sign In</Button></Tooltip> }
             />
        </>
    );
}