import React from 'react';
import GroupsView from '../views/GroupsView';
import ApplicationsView from '../views/ApplicationsView';
import UsersView from '../views/UsersView';
import HomeView from '../views/HomeView';
import {Routes ,Route } from 'react-router';
import PrivateComponent from '../components/PrivateComponent';
import UnAuthorizedView from '../views/UnAuthorizedView';
import UserInfoView from '../components/users/UserInfoView';
import UserInfoCreate from '../components/users/UserInfoCreate';
import GroupInfoView from '../components/groups/GroupInfoView';
import GroupInfoCreate from '../components/groups/GroupInfoCreate';
import AppInfoView from '../components/applications/AppInfoView';
import AppInfoCreate from '../components/applications/AppInfoCreate';

function MyRoutes(){
    return(
        <Routes>
            <Route exact path="/unauthorized" element={<UnAuthorizedView />} />
            <Route path="/" 
            element={
                <PrivateComponent>
                <HomeView />
                </PrivateComponent>
            } 
            />
            <Route path="/home" 
            element={
                <PrivateComponent>
                <HomeView />
                </PrivateComponent>
            } 
            />
            <Route path="/users" 
            element={
                <PrivateComponent>
                <UsersView />
                </PrivateComponent>
            } 
            />
            <Route path="/users/new" 
            element={
                <PrivateComponent>
                <UserInfoCreate />
                </PrivateComponent>
            } 
            />
            <Route path="/users/:id" 
            element={
                <PrivateComponent>
                <UserInfoView />
                </PrivateComponent>
            } 
            />
            <Route path="/groups" 
            element={
                <PrivateComponent>
                <GroupsView />
                </PrivateComponent>
            } 
            />
            <Route path="/groups/:id" 
            element={
                <PrivateComponent>
                <GroupInfoView />
                </PrivateComponent>
            } 
            />
            <Route path="/groups/new" 
            element={
                <PrivateComponent>
                <GroupInfoCreate />
                </PrivateComponent>
            } 
            />
            <Route path="/applications" 
            element={
                <PrivateComponent>
                <ApplicationsView />
                </PrivateComponent>
            } 
            />
            <Route path="/applications/:id" 
            element={
                <PrivateComponent>
                <AppInfoView />
                </PrivateComponent>
            } 
            />
            <Route path="/applications/new" 
            element={
                <PrivateComponent>
                <AppInfoCreate />
                </PrivateComponent>
            } 
            />
        </Routes>
    );
}

export default MyRoutes;