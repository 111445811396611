import React from 'react';
import { Card, Tabs } from 'antd';
import SettingsConfigForm from './forms/SettingsConfigForm';
import MembersConfigForm from './forms/MembersConfigForm';
import SubGroupsConfigForm from './forms/SubGroupsConfigForm';

function GroupConfigTab(props){

    const group = props.group;

    const items = [
        {
        key: '1',
        label: `Settings`,
        children: (<Card><SettingsConfigForm group={group}/></Card>), 
        },
        {
        key: '2',
        label: `Members`,
        children: (<Card><MembersConfigForm group={group}/></Card>),
        },
        {
        key: '3',
        label: `Subgroups`,
        children: (<Card><SubGroupsConfigForm group={group}/></Card>),
        disabled: true
        },
    ];

    return (
        <Tabs defaultActiveKey="1" centered items={items} size={20}/>
    );
}

export default GroupConfigTab;