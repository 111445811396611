import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { getClientSecret } from '../../../utils/clients/clientManagerService';
import { Descriptions } from 'antd';

function AppCredentialsForm(props){

    const app = props.app;
    const {keycloak} = useKeycloak();

    const [cred, setCred] = useState(
        {
            type:null,
            value:null
        }
    );

    useEffect(() => {
      
        getClientSecret(keycloak.token, app.id)
        .then((response) => {
            setCred(response)
        });
    }, [])
    

    return (
        <>
            <Descriptions column={1}>
                <Descriptions.Item label="Client Authenticator">Client Id and Secret</Descriptions.Item>
                <Descriptions.Item label="Client ID">{app.clientId}</Descriptions.Item>
                <Descriptions.Item label="Client Secret">{cred.value}</Descriptions.Item>
            </Descriptions>
        </>
    );

}

export default AppCredentialsForm;