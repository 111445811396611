import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Button, Collapse, Input, Switch, Divider,Tabs, Form, Select, Space, Spin} from 'antd';

import MainLayout from '../../layouts/MainLayout';
import { createNewApp } from '../../utils/clients/clientManagerService';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';
import { useKeycloak } from '@react-keycloak/web';

const { Panel } = Collapse;

function AppInfoCreate(){

    const [form] = Form.useForm();

    const items = [
        {
        key: '1',
        label: `Availability`,
        children: (
        <>
            <Form.Item label="Is Enabled" name="enabled" valuePropName="checked">
                <Switch/>
            </Form.Item>
        </>), 
        },
        {
        key: '2',
        label: `Grant Types`,
        children: (
        <>
            <Form.Item label="Direct Access Grant" name="grant" valuePropName="checked">
                <Switch />
            </Form.Item>
        </>),
        },
        {
        key: '3',
        label: `OAuth`,
        children: (
        <Row>
            <Space>
            <Form.Item labelCol={8} label="Standard Flow" name="flow" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item labelCol={8} label="Service Account" name="serv-acc" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item labelCol={8} label="Conset Required" name="cons-req" valuePropName="checked">
                <Switch />
            </Form.Item>
            </Space>
        </Row>
        )
        }
    ];

    const [processing, setProcessing] = useState(false);

    const navigate = useNavigate();

    const options = [];

    const defaultCORSoption = [{
        value: '*',
        label: '*',
      }];

    const appTypeOptions = [
        {
            value: 0,
            label: 'Public'
        },
        {
            value: 1,
            label: 'Confidential'
        }
    ];
    
    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
    
    const handleAppTypeChange = (value) => {
        console.log(`selected ${value}`);
      };

    const {keycloak} = useKeycloak
    ();

    useEffect(() => {
        form.setFieldsValue({
            enabled: true,
            apptype: 0
        });

    }, [form])
    
    const onFinish = () => {
        form
          .validateFields()
          .then((values) => {

            setProcessing(true);

            let app = {
                clientId: values.clientId,
                name: (values.name == null) ? '' : values.name,
                description: (values.description == null) ? '' : values.description,
                publicClient: (values.apptype === 0) ? true: false,
                serviceAccountsEnabled: values.service_enabled,
                directAccessGrantsEnabled: values.grant,
                enabled: values.enabled,
                rootUrl: values.rootUrl,
                redirectUris: values.callbackurls,
                webOrigins: values.corsurls
            }

            let data = JSON.stringify(app);

            setTimeout(() => {
                createNewApp(keycloak.token, data)
                .then((resp) => {
                    setProcessing(false);
                    showSuccessMessage("New application created successfully!")
                    navigate('/applications')
                }).catch((err) => {
                    setProcessing(false);
                    showErrorMessage("Something went wrong! Error: "+ err.message)
                    console.log(err)
                })
            }, 3000);

          }
        )
    }

    return (
        <MainLayout menu="3">
                <Row justify={'center'}>
                    <Col span={14}>
                        <Spin spinning={processing} tip="Saving...">
                            <Card>
                                <Form
                                    form={form}
                                    name="profile"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 24}}
                                    style={{padding: 25}}
                                    onFinish={onFinish}
                                    //onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <h3>
                                        Basic Information
                                    </h3>

                                    <Form.Item
                                    label="Client ID"
                                    name="clientId"
                                    rules={[
                                        {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please input a valid and unique clientID or ApplicationID!',
                                        },
                                    ]}
                                    >
                                    <Input />
                                    </Form.Item>

                                    <Form.Item
                                    label="Display Name"
                                    name="name"
                                    rules={[
                                        {
                                        required: false,
                                        message: 'Please input a valid name',
                                        },
                                    ]}
                                    >
                                    <Input />
                                    </Form.Item>

                                    <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                        required: false,
                                        message: 'Must be less than 200 characters',
                                        },
                                    ]}
                                    >
                                    <Input />
                                    </Form.Item>

                                    <Divider/>
                                    <h3>Application Properties</h3>

                                    <Form.Item
                                    label="Application Type"
                                    name="apptype"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Must choose application type',
                                        },
                                    ]}
                                    >
                                        <Select
                                            options={appTypeOptions}
                                            onChange={handleAppTypeChange}
                                            >
                                        </Select>
                                    </Form.Item>

                                    <Divider/>
                                    <h3>Application URIs</h3>
                                    <Form.Item
                                    label="Root URL"
                                    name="rootUrl"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Invalid URL',
                                        },
                                    ]}
                                    >
                                    <Input />
                                    </Form.Item>

                                    <Form.Item
                                    label="Callback URLs"
                                    name="callbackurls"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Error! At leat one url must be provided',
                                        },
                                    ]}
                                    >
                                    <Select
                                        mode="tags"
                                        onChange={handleChange}
                                        tokenSeparators={[',']}
                                        options={options}
                                    />
                                    </Form.Item>

                                    <Form.Item
                                    label="Web Origins (CORS)"
                                    name="corsurls"
                                    rules={[
                                        {
                                        required: false,
                                        },
                                    ]}
                                    >
                                    <Select
                                        mode="tags"
                                        onChange={handleChange}
                                        tokenSeparators={[',']}
                                        options={defaultCORSoption}
                                    />
                                    </Form.Item>

                                    <Collapse size="large" bordered={false} style={{marginLeft: 50}}>
                                        <Panel header="Advanced Settings" key="1">

                                            <Tabs defaultActiveKey="1" centered items={items} size={20}/>
                                            
                                        </Panel>
                                    </Collapse>

                                    <Form.Item>
                                        <Row style={{marginTop: 30}} justify={'space-around'}>
                                            <Button type="primary" htmlType="submit">Save Changes</Button>
                                        </Row>
                                    </Form.Item>

                                </Form>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </MainLayout>
    );
}

export default AppInfoCreate;