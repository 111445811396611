import React, {useState, useEffect} from 'react';
import {Layout, theme, Breadcrumb, Row, Col} from 'antd';
import { useLocation, Link} from 'react-router-dom';
import {HomeOutlined} from '@ant-design/icons';
import SideBar from '../components/Sidebar';
import UserDetails from '../components/userDetails';
import { useKeycloak } from '@react-keycloak/web';
import { getUserInfo } from '../utils/users/userManagerService';

const {Content, Footer, Sider} = Layout;

const breadcrumbNameMap = {
    '/users': 'Users',
    '/users/new': 'create',
    '/groups': 'Groups',
    '/groups/new': 'create',
    '/applications': 'Applications',
    '/applications/new': 'create',
    //'/apps/1/detail': 'Detail',
    //'/apps/2/detail': 'Detail',
  };

const MainLayout = (props) => {

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const [collapsed, setCollapsed] = useState(false);

    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return {
        key: url,
        title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
    });
    const breadcrumbItems = [
    {
        title: (
            <>
                <HomeOutlined />
                <span>Home</span>
            </>
        ),
        key: 'home',
    },
    ].concat(extraBreadcrumbItems);

    const {keycloak} = useKeycloak();
    const [userinfo, setUserinfo] = useState({})

    useEffect(() => {
      //implement logic here to get userinfo

        getUserInfo(keycloak.token).then((response) => {
            setUserinfo(response);
        });

    }, [])
    

    return (
        <Layout
            style={{minHeight: '100vh'}}>

                <Sider collapsible
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        style={{background: colorBgContainer}}
                        
                        width={250}>
                            <SideBar menu={props.menu}/>
                </Sider>
                <Layout className="site-layout">
                    <Content style={{margin: '0 16px'}}>
                        <Row justify={'space-between'} style={{margin: 16}}>
                            <Col span={19}>
                                <Breadcrumb items={breadcrumbItems} separator=">"/>
                            </Col>
                            <Col span={4}>
                                <UserDetails username={userinfo.preferred_username}></UserDetails>
                            </Col>
                            
                        </Row>
                        
                        <main style={{margin: 16}}>{props.children}</main>
                        
                    </Content>
                    <Footer style={{textAlign: 'center'}}>
                        Copyright © EO4EU {new Date().getFullYear()}
                    </Footer>
                </Layout>
            </Layout>
        
    );
}

export default MainLayout;
