import React, { useState, useEffect } from 'react';
import { Switch, Form, Select } from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import { getGroups } from '../../../utils/groups/groupManagerService';

function GroupAssignComponent(){

    const {keycloak} = useKeycloak();
    const [groups, setGroups] = useState();
    const [isMemberOfGroup, setIsMemberOfGroup] = useState(false);

    useEffect(() => {
      
        getGroups(keycloak.token)
        .then((response) => {
            let grps = [];

            for (let i = 0; i < response.length; i++) {
                grps.push({
                    label: response[i].name,
                    value: response[i].name
                });
                
            }
            setGroups(grps)
        });
    }, [])
    

    return (
        <>
            <Form.Item labelCol={8} label="Assign User To Group?" name="isGroupMember">
                <Switch  checked={isMemberOfGroup} onChange={(e) => setIsMemberOfGroup(e)}/>
            </Form.Item>

            <Select
                showSearch
                placeholder="Select a group"
                style={{width: '100%'}}
                disabled={!isMemberOfGroup}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={groups}
                />
        </>
    )
}

export default GroupAssignComponent;