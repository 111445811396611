import { notification } from "antd";

export const getSearchParams = () => {
    return window.location.search
      .substring(1)
      .split("&")
      .reduce(function(initial, item) {
        if (item) {
          var parts = item.split("=");
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});
  }
  
  export const removeSearchParamsFromUrl = () => {
    window.history.pushState("", document.title, window.location.pathname);
  }

  export const showSuccessMessage = (msg) =>{
    notification.success({
      message: 'Success!',
      placement: 'bottomRight',
      duration: 5, //time in seconds
      description: msg
    });
  }

  export const showErrorMessage = (msg) =>{
    notification.error({
      message: 'Error!',
      placement: 'bottomRight',
      duration: 5, //time in seconds
      description: msg
    });
  }