import apiRequest from "../../api/apiInstance";

export const getGroupsCount = async (token) => {
    const config = {
        method: 'GET',
        url: '/Groups/count',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getGroups = async (token) =>{
    
    const config = {
        method: 'GET',
        url: '/Groups',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getGroupDetailsPerId = async (token, id) =>{
    const config = {
        method: 'GET',
        url: '/Groups/'+ id,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const getGroupMembersPerGroupId = async (token, id) =>{
    const config = {
        method: 'GET',
        url: '/Groups/'+ id + '/members',
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}

export const createNewGroup = async (token, data) => {
    const config = {
        method: 'POST',
        url: '/Groups',
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const editExistingGroup = async (token, groupId, data) => {

    const config = {
        method: 'PUT',
        url: '/Groups/' + groupId,
        headers: {
            "Authorization": 'Bearer '+ token
        },
        data: data
    };

    return await apiRequest(config);
}

export const deleteGroup = async (token, groupId) => {

    const config = {
        method: 'DELETE',
        url: '/Groups/' + groupId,
        headers: {
            "Authorization": 'Bearer '+ token
        }
    };

    return await apiRequest(config);
}