import React from 'react';
import {Card, Row, Col, Avatar} from 'antd';
import {TeamOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
//import { getGroupMembersPerGroupId } from '../utils/groups/groupManagerService';
import { useKeycloak } from '@react-keycloak/web';

export default function CustomGroupGrid(props){

    const navigate = useNavigate();

    const cards = [];
    const items = props.items.groups;

    const OpenDetails = (e, id) =>{
        navigate('/groups/'+id);
    }

    const { Meta } = Card;
    for (let index = 0; index < items.length; index++) {

        let item = items[index];
        //let members = items[index].members;

        cards.push(
            <Col span={6} key={index}>
                <Card
                    hoverable={true}
                    /* style={{
                    width: 300,
                    marginTop: 16,
                    }} */
                    key={index}
                    onClick={(e) =>{OpenDetails(e, item.id)}}
                >
                    <Meta
                    avatar={<Avatar icon={<TeamOutlined />} style={{
                        backgroundColor: '#d4eff9',
                        color: '#4490be',
                      }}/>}
                    title={<span style={{textTransform: 'capitalize'}}> {item.name}</span>}
                    //description={<b>Members: {members}</b>}
                    />
                </Card>
            </Col>
        )
    }

    return(
        <Row gutter={[16, 8]} style={{marginTop: 10, marginBottom:10}}>
            {cards}
        </Row>
    );
}