import React from 'react';
import {Card, Row, Col, Avatar} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function CustomUserGrid(props){

    const cards = [];
    const items = props.items.users;

    const navigate = useNavigate();
    const { Meta } = Card;

    const OpenDetails = (e, id) =>{
        navigate('/users/'+id);
    }

    for (let index = 0; index < items.length; index++) {
        cards.push(
            <Col span={6} key={index}>
                <Card
                    hoverable={true}
                    /* style={{
                    width: 300,
                    marginTop: 16,
                    }} */
                    onClick={(e) =>{OpenDetails(e, items[index].id)}}
                >
                    <Meta
                    avatar={<Avatar icon={<UserOutlined />} style={{
                        backgroundColor: '#d4eff9',
                        color: '#4490be',
                      }}/>}
                    title={<span style={{textTransform: 'capitalize'}}> {items[index].username}</span>}
                    description={<b>Email: {items[index].email ? items[index].email : "None"}</b>}
                    />
                </Card>
            </Col>
        )
    }

    return(
        <Row gutter={[16, 8]} style={{marginTop: 10, marginBottom:10}}>
            {cards}
        </Row>
    );
}