import { useKeycloak } from '@react-keycloak/web';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { getUserDetailsPerId } from '../../utils/users/userManagerService';
import MainLayout from '../../layouts/MainLayout';
import UserConfigTab from './UserConfigTab';
import { Col, Row, Spin } from 'antd';

function UserInfoView(){

    const {id} = useParams();
    const {keycloak} = useKeycloak();

    const token = keycloak.token;

    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      getUserDetailsPerId(token, id)
      .then((response) => {
        setUser(response);
        setIsLoading(false);
        });
    }, [])
    
    
    return (
        <MainLayout menu="1">
            <Row justify={'center'}>
                <Col span={16}>
                    <Spin spinning={isLoading} size="large" tip="Loading">
                        <UserConfigTab user={user}/>
                    </Spin>
                </Col>
            </Row>
        </MainLayout>
    );
}

export default UserInfoView;